<template>
  <div class="blog-post">
    <Navbar/>  
    <div class="container-fluid">
      <SubNav>
        <template #link1>
          <router-link to="/blog"> 
            <u>Blog</u>>
          </router-link>
        </template>
        <template #current>
          Высшее образование во Франции
        </template>
      </SubNav>
      <div class="row">
        <div class="col-12 offset-lg-3 col-lg-6">
          <PostHeader>
            <template #title>
                Высшее образование во Франции <br>
                Университет или высшая школа? Что выбрать?
            </template>
            <template #date>
                12.05.2021
            </template>
          </PostHeader>

          <PostBodyIntroduction>
              <template #introduction>
                  <p>
                      Французская система образования достаточно сильно отличается от российской. Она подразделяется на 2 главных направления: университеты и высшие школы. У каждого из них есть свои преимущества и недостатки. 
                  </p>
              </template>
          </PostBodyIntroduction>

          <PostBodySection>
            <template #title>
              Университеты Франции
            </template>
            
            <template #content>
                <p>
                    Главный девиз университетов – возможность получения образования для всех. Туда зачисляются ученики сразу после школы, на большинство направлений нет вступительных экзаменов – достаточно сдать школьный экзамен BAC, чем-то похожий на русский ЕГЭ. Плата за образование в университете достаточно символическая. Финансирование вузов идет за счет государства. При этом процент иностранных студентов достаточно высок и порой достигает 75%. Для поступления на первый курс французского университета достаточно школьного диплома, при этом нужно знать язык не ниже уровня B2. Университеты присуждают три вида степени в соответствии с европейской системой. Первая степень (3 года) – Licence, вторая степень - это Master, магистр (5 лет обучения), последняя степень, полученная после 8 лет – докторская.
                </p>
                <p>
                    Как правило, французские университеты принимают российские дипломы, но зачастую берут на обучение, ниже на 1 степень. То есть если человек учился 5 лет в университете в России, то он может сразу поступить на 2-й год магистратуры. С недавнего времени цены на обучение во французских университетах выросли для иностранных студентов и в среднем составляют 3000 евро. 
                </p>
                <p>
                    Учеба в вузах Франции, особенно на последних курсах, рассчитана на самостоятельное обучение. Большую часть времени студенты проводят в библиотеке. Для поступления в магистратуру студенту необходимо заявить тему дипломной работы и выбрать научного руководителя. Как правило, профессоры французских вузов берут около 20 человек в год. Оценки строятся по 20-балльной шкале, где 10 – это проходной балл. 
                </p>
                <p>
                    Если во всем мире университеты считаются главными носителям качественного элитного образования, то во Франции преимущество отдано высшим школам. Во Франции насчитывается более 100 университетов, но при этом главная задача у них – это научные работы и академическая направленность. Высокопоставленные чиновники, ведущие политики, физики и инженере и топ-менеджеры – выпускники высших школ. 
                </p>
            </template>
          </PostBodySection>

          <PostBodySection>
            <template #title>
              Высшие школы во Франции
            </template>
            
            <template #content>
                <p>
                    Если в университете студенты получают более академическое образование, то высшие школы нацелены выпускать специалистов высокого класса, готовых сразу после окончания обучения выйти на рынок труда. 
                </p>
                <p>
                    Для поступления студенты сдают экзамены. Одной из самых известных Grande Ecole является «Polytechnique», которая была основана в 1794 году как школа общественной инженерии. Студенты проходят 4 года обучения, где сильны точные дисциплины, а французские студенты имеют статус офицеров армии. «Polytechnique» считается одним из лучших в мире «университетов» и поддерживает обмены с другими учреждениями в той же лиге: MIT, Гарвард, Стэнфорд, Оксфорд, ICL и другими.
                </p>
                <p>
                    Еще одна очень высокопоставленная школа - Высшая административная школа, ENA, ведущее учреждение Франции по подготовке будущих высокопоставленных государственных служащих (высокопоставленных чиновников), ведущих политиков и менеджеров. Однако последнее время школа подверглась общественной критике. Президент Эммануэль Макрон, будучи выпускником этой школы, сказал о ее скором закрытии. 
                </p>
                <p>
                    Другие известные Grandes Ecoles или Grands Etablissements включают Ecole des Mines, Ecole Normale Supérieure (высшее учебное заведение по предметам искусства, а также школы бизнеса и управления мирового класса, такие как HEC, INSEAD ESSEC, ESCP Europe, Emlyon и другие. Рейтинг бизнес-школ ежегодно публикуют такие издания как Figaro, L’étudiant, Financial Times. В рейтинг включен ряд критериев, в том числе и зарплата учеников после окончания учебного заведения и через несколько лет, процент выпускников на позициях топ-менеджеров. Выпускники бизнес-школ первой десятки могут рассчитывать на зарплату в 35.000-40.000 евро в год, при условии постоянного контракта.
                </p>
                <p>
                    Престижной высшей школой также является Sciences Po - Институт политических исследований Парижа - автономная высшая школа, финансируемая государством.
                </p>
                <p>
                    Большое преимущество бизнес-школ Франции – это связи и доступ к ведущим компаниям. Вся система построена так, что выпускники одной и той же школы заинтересованы в том, чтобы помогать друг другу, ведь именно от этого зависит рейтинг школы и их будущая зарплата в том числе. 
                </p>
                <p>
                    Французы очень большое внимание уделяют образованию. При поиске работы, даже имея огромный опыт, они все равно смотрят на то, какое высшее заведение закончил человек. Не редки случаи, когда в описании вакансии четко стоит требование иметь диплом высшей школы. 
                </p>
                <p>
                    Обучение в высших школах можно проходить как на французском языке, так и на английском. Как правило, это зависит от конкретной программы. Для поступления нужно сдать ряд экзаменов, как правило это тест на знание языка, тесты на логику и математику – GMAT или SHL, собеседование. Экзамены варьируются в зависимости от школы и программы обучения. Также для поступления необходимо предоставить мотивационное письмо, резюме и рекомендательные письма. 
                </p>
                <p>
                    Готовиться к поступлению в высшие школы французы начинают еще со школы. Для этого существуют специальные двухгодичные подготовительные классы, которые называются prépa, вступительные экзамены на нее сдают лишь 7% учащихся, при этом треть поступивших не выдерживают интенсивной программы в первый год и уходят в государственные вузы. После окончания prépa, молодые люди получают диплом, равный двум годам бакалавриата. 
                </p>
                <p>
                    Поступить в высшие школы можно и после обучения в государственном университете. Для этого необходимо сдать экзамены. 5 лет обучения в России, как правило, засчитывают во Франции за 4 года. Таким образом, есть возможность поступить сразу на второй год магистратуры. Если французам поступить в высшие школы крайне сложно, то иностранцам в этом плане немного легче, для них существуют определенные квоты. На рейтинг школы также влияет количество иностранных студентов и широта представленных национальностей.
                </p>
                <p>
                    Один год обучения в высшей школе будет стоить 6.000-20.000 евро, в зависимости от рейтинга школы и выбранной программы. Средний возраст учащихся магистратуры –25 лет, но есть и студенты, которым за 30. Люди с опытом выбирают программы MBA и Executive MBA, цены на эти программы выше, начиная от 15.000 евро и заканчивая 40.000. 
                </p>
                <p>
                    Во Франции предусмотрена система грантов и стипендий, как от государства, так и от частных инвесторов. При правильно поданной заявке можно сэкономить 50% от стоимости обучения. 
                </p>
            </template>
          </PostBodySection>

          <PostBodySection>
            <template #title>
              Alternance
            </template>
            
            <template #content>
                <p>
                    Программа, на которую могут попасть учащиеся в возрасте до 30 лет. Ее смысл в том, что студент совмещает учебу в высшей школе с практикой на предприятии. График обговаривается с компанией и высшим учебным заведением. Например, 3 дня в компании и 2 дня в школе, либо наоборот. Преимущество этой системы в том, что компания частично платит за обучение. По окончании alternance выпускник имеет не только престижный диплом, но и опыт работы. Практически все крупные французские фирмы предоставляют такую программу. Для зачисления на нее нужно не только пройти вступительные экзамены в высшую школу, но и собеседование с компаний.
                </p>
            </template>
          </PostBodySection>

          <PostBodySection>
            <template #title>
              Стажировки во Франции
            </template>
            
            <template #content>
                <p>
                    Практически все высшие школы подразумевают прохождения стажировки во время обучения, это обязательный критерий получения диплома. Как правило, стажировка длится 3 – 6 месяцев, за нее студент в среднем получает от 500 до 1500 евро в месяц. 
                    Высшие школы заинтересованы в том, чтобы студенты находили престижные стажировки, именно поэтому во время обучения они организуют постоянные мастер-классы и поддержку карьерных наставников: здесь рассказывают как правильно составить резюме и сопроводительное письмо, оформить заявку на сайте компании, тренируют как правильно решать бизнес-кейсы и безукоризненно отвечать на сложные вопросы во время интервью. 
                </p>
            </template>
          </PostBodySection>

          <PostBodySection>
            <template #title>
              Карьерные ярмарки
            </template>
            
            <template #content>
                <p>
                    Для того, чтобы найти стажировку, высшие школы часто проводят карьерные ярмарки. На них приходят крупные международные фирмы, заинтересованные нанять выпускников именно из этой школы. Это отличная возможность узнать больше о самих компаниях, их вакансиях и пройти первый предварительный отбор. Затем следует серия собеседований, в большинстве случаев, отбор на стажировку - это 1-2 интервью. 
                </p>
            </template>
          </PostBodySection>

          <PostBodySection>
            <template #title>
              Сообщество выпускников
            </template>
            
            <template #content>
                <p>
                    Еще одно важное преимущество высших школ – это сообщество выпускников. Своеобразный закрытый клуб, который призван поддерживать твой карьерный рост. Несколько раз в месяц здесь проводятся мастер-классы для специалистов как с опытом работы, так и недавних выпускников. Существует закрытая база вакансий и регулярно организуются тематические нетворкинг-вечера по всему миру. Например, для работников маркетинга, финансовой сферы или консалтинговых услуг. 
                </p>
            </template>
          </PostBodySection>

          <PostBodySection>
            <template #title>
              Итог
            </template>
            
            <template #content>
                <p>
                    С точки зрения стоимости обучения, дешевле будет учиться во французском университете, чем в высшей школе. Академическое образование во французских вузах находится на высоком уровне. Однако, стоит учитывать цели, которые вы преследуете. Если в планах есть остаться во Франции и получить высокооплачиваемую работу, то выбор стоит сделать в сторону высшей школы. Если вы точно знаете, что хотите вернуться на Родину после обучения – тогда диплом французского университета и свободное владения иностранным языком обеспечат вам серьезное конкурентное преимущество на рынке труда. 
                </p>
            </template>
          </PostBodySection>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/common/Navbar.vue'
import PostHeader from '@/components/blog/slots/PostHeader.vue'
import PostBodyIntroduction from '@/components/blog/slots/PostBodyIntroduction.vue'
import PostBodySection from '@/components/blog/slots/PostBodySection.vue'
import SubNav from '@/components/common/slots/SubNav.vue'
export default {
  name: 'BlogPost3',
  components: {
    Navbar,
    PostHeader,
    PostBodyIntroduction,
    PostBodySection,
    SubNav
  }
}
</script>
